export default {
	issuer: 'token:prod',
	host: 'https://www.videoland.com',
	identity_host: 'https://account.rtl.nl',
	v1_base: 'https://www.videoland.com/api',
	rtl_oidc_base: 'https://api.videoland.com/subscribe/rtl-oidc/oidc/videoland-reset-password',
	subscription_base: 'https://api.videoland.com/subscribe/subscription',
	newsletter_base: 'https://api.videoland.com/subscribe/newsletter',
	accounts_base: 'https://api.videoland.com/subscribe/videoland-account',
	payment_base: 'https://api.videoland.com/subscribe/payment',
	billing_base: 'https://api.videoland.com/subscribe/billing',
	statics_base: 'https://static.videoland.com/',
	gigya_base: 'https://cdns.gigya.com/JS/gigya.js?apiKey=',
	gigya_key: '4_hRanGnYDFjdiZQfh-ghhhg',
	onetrust_domain_id: 'bf9bd4ea-550d-41e9-8de4-98f08ebdbce6',
	api_base: 'https://api.videoland.com',
	bedrock: 'https://v2.videoland.com/',
	opentelemetry_url:
		'https://api.rtl.nl/monitoring/opentelemetry-collector-frontend/receivers/otlphttp/v1/traces',
};
