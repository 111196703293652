import { getEndpointUrl, getExternalUrl } from '../config';
import { analyticsPush } from './applicationActions';
import history from '../../common/history';
import { ROUTE_EMAIL_VERIFIED, ROUTE_REGISTER } from '../constants/Routes';
import { EVT_CHANGE_TIER_ERROR, EVT_CHANGE_TIER_SUCCESS } from '../constants/EventTypes';
import {
	isFunction,
	reportCustomError,
	reportError,
	startUseCase,
} from '../helpers/realUserMonitoring';
import { gigyaVerifyLogin } from './authenticationActions';
import { redirectIfNoActiveSubscription } from '../helpers/redirect';
import {
	SUBSCRIPTION_CANCEL,
	SUBSCRIPTION_FETCH_TIER,
	SUBSCRIPTION_CHANGE_TIER,
	FETCH_SUBSCRIBE_NEWSLETTER,
	SUBSCRIBE_NEWSLETTER,
	CANCEL_SUBSCRIPTION_NEWSLETTER,
	SET_SUBSCRIPTION,
	SET_SUBSCRIPTION_TIER,
	SUBSCRIPTIONS_FETCH,
	SUBSCRIPTION_CLEAR,
} from '../constants/ActionTypes';
import subscriptionService from '../api/subscriptionService';
import mutationCancelSubscription from '../graphql/mutationCancelSubscription.graphql';
import mutationSwitchTier from '../graphql/mutationSwitchTier.graphql';
import querySubscriptionTier from '../graphql/querySubscriptionTier.graphql';
import querySubscription from '../graphql/querySubscription.graphql';
import { createReactivateSubscriptionAction } from './profile/reactiveSubscription';
import { GIGYA_ACCOUNT_PENDING_REGISTRATION } from '../constants/ErrorTypes';
import {
	OperationNames,
	SpanCustomAttributesNames,
	SpanCustomAttributesValues,
	SpanNames,
} from '../constants/OtelSpans';
import { OperationTelemetry } from '@rtl_nl/rtl-otel-js-web';

const cancelSubscriptionTelemetry = new OperationTelemetry(OperationNames.CANCEL_SUBSCRIPTION);

export const cancelSubscription = () => async (dispatch) => {
	function dispathcFetchSubscriptionTier({ endSpan } = {}) {
		if (isFunction(endSpan)) endSpan();
		dispatch(fetchSubscriptionTier(cancelSubscriptionTelemetry));
	}
	function dispatchCancelSubscription({ endRootSpan, recordRootSpanError } = {}) {
		dispatchCancelGraphQl()
			.then(() =>
				startUseCase({
					telemetry: cancelSubscriptionTelemetry,
					spanName: SpanNames.SUBSCRIPTION_CANCELED,
					callBack: dispathcFetchSubscriptionTier,
				})
			)
			.then(() => {
				dispatch(fetchSubscription(cancelSubscriptionTelemetry));
				if (isFunction(endRootSpan)) endRootSpan();
			}) // refresh the subscription to get nextStatusEffectiveDate and nextStatus
			.catch((err) => {
				if (isFunction(recordRootSpanError)) recordRootSpanError(err);
				if (isFunction(endRootSpan)) endRootSpan();
				reportError(err);
				throw err;
			})
			.finally(() => {
				if (isFunction(endRootSpan)) endRootSpan();
			});
	}

	return startUseCase({
		telemetry: cancelSubscriptionTelemetry,
		spanName: SpanNames.SUBSCRIPTION_CANCEL_ATTEMPT,
		callBack: dispatchCancelSubscription,
		isRootSpan: true,
	});

	function dispatchCancelGraphQl() {
		return dispatch({
			type: SUBSCRIPTION_CANCEL,
			payload: {
				client: 'subscriptionService',
				url: getEndpointUrl('graphql_path'),
				mutation: mutationCancelSubscription,
			},
		});
	}
};
export const fetchSubscription = (parentTelemetry) => async (dispatch, getState) => {
	async function dispatchFetchSubscription({ endSpan, recordSpanError, setSpanAttribute } = {}) {
		try {
			const response = await dispatch({
				type: SUBSCRIPTIONS_FETCH,
				payload: {
					client: 'subscriptionService',
					url: getEndpointUrl('graphql_path'),
					query: querySubscription,
					fetchPolicy: 'no-cache',
				},
			});
			const state = getState();

			if (state.router?.location.pathname !== ROUTE_EMAIL_VERIFIED) {
				if (isFunction(setSpanAttribute))
					setSpanAttribute(
						SpanCustomAttributesNames.LOGIN_EVENT,
						SpanCustomAttributesValues.LOGIN_REDIRECT_NO_SUBSCRIPTION
					);

				if (isFunction(endSpan)) endSpan();

				return redirectIfNoActiveSubscription({
					subscriptions: response.payload.data?.me.subscription,
					router: state.router,
					redirectUrl: state.authentication?.redirectUrl,
				});
			}
		} catch (err) {
			if (isFunction(recordSpanError)) recordSpanError(err);
			if (isFunction(endSpan)) endSpan();
			reportError(err);
			throw err;
		}
	}

	await startUseCase({
		telemetry: parentTelemetry,
		spanName: SpanNames.FETCH_SUBSCRIPTION,
		callBack: dispatchFetchSubscription,
	});
};

export const setSubscription = (data) => (dispatch) => {
	dispatch({
		type: SET_SUBSCRIPTION,
		payload: data,
	});
};

export const subscriptionClear = () => (dispatch) => {
	dispatch({
		type: SUBSCRIPTION_CLEAR,
	});
};

export const fetchSubscribeNewsletter = () => (dispatch) =>
	dispatch({
		type: FETCH_SUBSCRIBE_NEWSLETTER,
		payload: {
			client: 'newsletterService',
			url: getEndpointUrl('get_subscribe_newsletter'),
			method: 'GET',
		},
	}).catch((err) => {
		reportError(err);
		throw err;
	});

export const subscribeNewsletter = () => (dispatch) =>
	dispatch({
		type: SUBSCRIBE_NEWSLETTER,
		payload: {
			client: 'newsletterService',
			url: getEndpointUrl('subscribe_newsletter'),
			method: 'PUT',
			data: {
				source: 'videoland-website-subscribeflow',
				newsletter: 'videoland_newsletter',
			},
		},
	}).catch((err) => {
		reportError(err);
		throw err;
	});

export const cancelSubscriptionNewsletter = () => (dispatch) =>
	dispatch({
		type: CANCEL_SUBSCRIPTION_NEWSLETTER,
		payload: {
			client: 'newsletterService',
			url: getEndpointUrl('cancel_newsletter'),
			method: 'DELETE',
		},
	}).catch((err) => {
		reportError(err);
		throw err;
	});

export const reactivateSubscription = createReactivateSubscriptionAction(subscriptionService);
export const verifyLogin = () => (dispatch) => {
	dispatch(gigyaVerifyLogin()).catch(({ error }) => {
		if (error.errorCode === GIGYA_ACCOUNT_PENDING_REGISTRATION) {
			history.push(ROUTE_REGISTER);
		}
	});
};

export const logoff = () => {
	if (__CLIENT__) {
		window.location.replace(getExternalUrl('logout'));
	}
};

export const fetchSubscriptionTier = (parentTelemetry) => (dispatch) => {
	function dispatchFetchSubscriptionTier({ endSpan, recordSpanError } = {}) {
		return dispatch({
			type: SUBSCRIPTION_FETCH_TIER,
			payload: {
				client: 'subscriptionService',
				url: getEndpointUrl('graphql_path'),
				query: querySubscriptionTier,
				fetchPolicy: 'no-cache',
			},
		})
			.then(() => {
				if (isFunction(endSpan)) endSpan();
			})
			.catch((err) => {
				if (isFunction(recordSpanError)) recordSpanError(err);
				if (isFunction(endSpan)) endSpan();
			});
	}
	return startUseCase({
		telemetry: parentTelemetry,
		spanName: SpanNames.FETCH_SUBSCRIPTION_TIER,
		callBack: dispatchFetchSubscriptionTier,
	});
};

export const setSubscriptionTier = (data) => (dispatch) => {
	return dispatch({
		type: SET_SUBSCRIPTION_TIER,
		payload: data,
	});
};

export const changeSubscriptionTier =
	({ tierId, changeSubscriptionTierTelemetry }) =>
	(dispatch) => {
		function dispatchChangeSubscriptionTier({ endRootSpan, recordRootSpanError } = {}) {
			return dispatch({
				type: SUBSCRIPTION_CHANGE_TIER,
				payload: {
					client: 'subscriptionService',
					url: getEndpointUrl('graphql_path'),
					mutation: mutationSwitchTier,
					variables: { tierId },
				},
			})
				.then(({ payload }) => {
					const { switchTier } = payload.data;

					if (switchTier.__typename === 'TierSwitchSuccess') {
						dispatch(analyticsPush({ newTierId: tierId }, EVT_CHANGE_TIER_SUCCESS));
						if (isFunction(endRootSpan)) endRootSpan();
						return switchTier;
					} else {
						const err = {
							name: 'switch_tier_failure',
							error: switchTier.dummy,
							message: 'Switch tier failed for user',
						};
						reportCustomError(err);
						dispatch(
							analyticsPush({ label: 'Switch tier failed for user' }, EVT_CHANGE_TIER_ERROR)
						);
						dispatch({ type: `${SUBSCRIPTION_CHANGE_TIER}_ERROR` });
						if (isFunction(recordRootSpanError)) recordRootSpanError(err);
						if (isFunction(endRootSpan)) endRootSpan();
					}
				})
				.catch((err) => {
					if (isFunction(recordRootSpanError)) recordRootSpanError(err);
					reportError(err);
					dispatch(analyticsPush({ label: err.message }, EVT_CHANGE_TIER_ERROR));
					dispatch({ type: `${SUBSCRIPTION_CHANGE_TIER}_ERROR` });
					if (isFunction(endRootSpan)) endRootSpan();
				});
		}

		return startUseCase({
			telemetry: changeSubscriptionTierTelemetry,
			spanName: SpanNames.CHANGE_TIER_ATTEMPT,
			callBack: dispatchChangeSubscriptionTier,
			isRootSpan: true,
		});
	};
