import React, { useEffect, useState } from 'react';
import routes from '../../routes';
import { AnalyticsStarter } from '../../components/analytics/AnalyticsStarter';
import { useRedirectIfTransactionInProgress } from '../../hooks/useRedirectIfTransactionInProgress';
import { useRedirectUser } from '../../hooks/useRedirectUser';
import { useOnboardingGeoBlock } from '../../hooks/useOnboardingGeoBlock';
import UsabillaStarter from '../analytics/UsabillaStarter';
import { useHandleRedirectReason } from '../../hooks/useHandleRedirectReason';
import { useGigya } from '../../hooks/useGigya';
import { stateRender } from '../../helpers/react';
import { useSubscription } from '../../hooks/useSubscription';
import { useSubscriptionTier } from '../../hooks/useSubscriptionTier';
import { hasValidVideolandCookie } from '../../helpers/authentication';
import { useDispatch, useSelector } from 'react-redux';
import {
	gigyaGetAccountInfo,
	videolandLogin,
	setVideolandCookie,
} from '../../actions/authenticationActions';
import { useHistory, matchPath, useLocation } from 'react-router';
import {
	ROUTE_LOGIN,
	ROUTE_LOGOUT,
	ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED,
	ROUTE_PAY_BAD_DEBT,
	ROUTE_PPE,
	ROUTE_TIER_OPTIONS,
	ROUTE_TIER_OPTIONS_WINBACK,
	ROUTE_REGISTER,
	ROUTE_ACTIVATE_VOUCHER,
} from '../../constants/Routes';
import { useGetUserInfo } from '../../hooks/useGetUserInfo';
import {
	initiateErrorTracking,
	initateLinkClickTracking,
	refreshLinkClickTracking,
	addToGlobalContexts,
} from '../analytics/Snowplow';
import { getConsentCategories } from '../../helpers/consent-helper';
import useInitTrackPageView from '../../hooks/useInitTrackPageView';

export function App() {
	const location = useLocation();
	const [isLoading, setIsloading] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const isLoggedIn = hasValidVideolandCookie();
	const isLogoutPage = !!matchPath(location.pathname, ROUTE_LOGOUT);
	const isLoginPage = !!matchPath(location.pathname, ROUTE_LOGIN);
	const gigyaUser = useSelector((state) => state.authentication.gigyaUser);

	initiateErrorTracking();

	const {
		loading: isLoadingUserGetMeInfo,
		user: { email },
	} = useGetUserInfo();
	const { isReady: isGigyaReady, isError: isGigyaError } = useGigya();

	useEffect(() => {
		initateLinkClickTracking();
		addToGlobalContexts({
			rtlConsent: {
				tcConsentLevel: getConsentCategories().toString(),
				tcSource: 'OneTrust',
				tcVersion: 'TCFv2',
			},
		});
	}, []);

	useEffect(() => {
		refreshLinkClickTracking();
	}, [location.pathname]);

	useEffect(() => {
		if (isGigyaReady && !isLogoutPage && Object.keys(gigyaUser).length === 0) {
			dispatch(gigyaGetAccountInfo())
				.then((result) => {
					if (result.payload.profile.email !== email) {
						dispatch(videolandLogin()).then(() => {
							dispatch(setVideolandCookie());
							setIsloading(false);
						});
					} else {
						setIsloading(false);
					}
				})
				.catch(() => {
					setIsloading(false);
					if (!isLoginPage && isLoggedIn) history.push(ROUTE_LOGOUT);
					return;
				});
		} else if (isLogoutPage || isGigyaError) {
			setIsloading(false);
		}
	}, [
		dispatch,
		email,
		gigyaUser,
		history,
		isGigyaReady,
		isLoggedIn,
		isLoginPage,
		isLogoutPage,
		isGigyaError,
	]);

	const allowPending = !matchPath(location.pathname, {
		path: [
			ROUTE_TIER_OPTIONS,
			ROUTE_TIER_OPTIONS_WINBACK,
			ROUTE_PAY_BAD_DEBT,
			ROUTE_PPE,
			ROUTE_LOGIN,
			ROUTE_REGISTER,
			ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED,
			ROUTE_ACTIVATE_VOUCHER,
		],
		exact: true,
		strict: false,
	});

	const { success: isGeoBlockedReady } = useOnboardingGeoBlock();

	const { loading: isRedirectIfTransLoading } = useRedirectIfTransactionInProgress();
	const { loading: isSubscriptionLoading, error: subscriptionHasError } =
		useSubscription(isLoading);

	const { loading: isSubscriptionTierLoading } = useSubscriptionTier(isLoading);

	const isAppPending =
		isLoading ||
		isLoadingUserGetMeInfo ||
		!isGeoBlockedReady ||
		(!isGigyaReady && !isGigyaError) ||
		(isLoggedIn && (isSubscriptionLoading || isSubscriptionTierLoading));

	useRedirectUser({ skip: isRedirectIfTransLoading });
	useHandleRedirectReason({ skip: isSubscriptionLoading });
	useInitTrackPageView(isLoading);

	return stateRender({
		isPending: allowPending && isAppPending,
		isError: subscriptionHasError,
		spinnerModifierClass: 'spinner--large centered',
		onSuccess: () => (
			<>
				<UsabillaStarter />
				<AnalyticsStarter>{routes}</AnalyticsStarter>
			</>
		),
	});
}
