export const ROUTE_ACCOUNT_BEDROCK = '/mijn-abonnement';
export const ROUTE_CHANGE_TIER_BEDROCK = `${ROUTE_ACCOUNT_BEDROCK}/abonnement-wijzigen`;
export const ROUTE_CHANGE_PAYMENT_DETAILS_BEDROCK = `${ROUTE_ACCOUNT_BEDROCK}/betaalgegevens-wijzigen`;
export const ROUTE_CHANGE_PAYMENT_DETAILS_STATUS_BEDROCK = `${ROUTE_ACCOUNT_BEDROCK}/betaalgegevens-wijzigen/status`;
export const ROUTE_INVOICE = `${ROUTE_ACCOUNT_BEDROCK}/factuur/:invoiceId`;
export const ROUTE_HOME = '/';
export const ROUTE_TIER_OPTIONS = '/abonnementen';
export const ROUTE_TIER_OPTIONS_WINBACK = `${ROUTE_TIER_OPTIONS}/welkom-terug`;
export const ROUTE_TIER_OPTIONS_REFERRAL = `${ROUTE_TIER_OPTIONS}/referral`;
export const ROUTE_CONNECT = '/koppelen';
export const ROUTE_YEAR = '/year';
export const ROUTE_CONTACT = '/contact';
export const ROUTE_COMPANY_INFORMATION = '/bedrijfsgegevens';
export const ROUTE_FALLBACK = '/fallback';
export const ROUTE_LOGIN = '/inloggen';
export const ROUTE_LOGIN_EMAIL = `${ROUTE_LOGIN}/email`;
export const ROUTE_LOGIN_RTLXL = `${ROUTE_LOGIN}/rtlxl`;
export const ROUTE_LOGIN_LINK = `${ROUTE_LOGIN}/koppelen`;
export const ROUTE_LOGIN_LINK_SUCCESS = `${ROUTE_LOGIN}/koppelen-succes`;
export const ROUTE_LOGIN_LINK_NOTIFICATION = `${ROUTE_LOGIN}/koppelen-bevestigen`;
export const ROUTE_LOGOUT = '/uitloggen';
export const ROUTE_FORGOT_PASSWORD = '/wachtwoord-vergeten';
export const ROUTE_FORGOT_PASSWORD_SENT = `${ROUTE_FORGOT_PASSWORD}/verstuurd`;
export const ROUTE_NEW_PASSWORD = '/nieuw-wachtwoord';
export const ROUTE_NEW_PASSWORD_UPDATED = `${ROUTE_NEW_PASSWORD}/aangepast`;
export const ROUTE_NEW_PASSWORD_INVALID = `${ROUTE_NEW_PASSWORD}/link-verlopen`;
export const ROUTE_FORGOT_PASSWORD_NEW = `${ROUTE_FORGOT_PASSWORD}/nieuw-wachtwoord`;
export const ROUTE_FORGOT_PASSWORD_UPDATED = `${ROUTE_FORGOT_PASSWORD}/aangepast`;
export const ROUTE_REGISTER = '/aanmelden';
export const ROUTE_REGISTER_INCOMPLETE = `${ROUTE_REGISTER}/voltooien`;
export const ROUTE_REGISTER_NAME = `${ROUTE_REGISTER}/naam`;
export const ROUTE_REGISTER_BIRTHDATE = `${ROUTE_REGISTER}/geboortedatum`;
export const ROUTE_REGISTER_GENDER = `${ROUTE_REGISTER}/geslacht`;
export const ROUTE_REGISTER_EMAIL = `${ROUTE_REGISTER}/email`;
export const ROUTE_REGISTER_PASSWORD = `${ROUTE_REGISTER}/wachtwoord`;
export const ROUTE_REGISTER_PRIVACY = `${ROUTE_REGISTER}/privacy`;
/* Hotfix for /aanmelden/null redirects */
export const ROUTE_REGISTER_NULL = `${ROUTE_REGISTER}/null`;
export const ROUTE_VERIFY_EMAIL = `${ROUTE_REGISTER}/verifieer-email`;
export const ROUTE_EMAIL_VERIFIED = `${ROUTE_REGISTER}/email-geverifieerd`;
export const ROUTE_LINK = `${ROUTE_REGISTER}/link`;
export const ROUTE_LINK_ALREADY_USED = `${ROUTE_LINK}/al-eens-gebruikt`;
export const ROUTE_LINK_EXPIRED = `${ROUTE_LINK}/verlopen`;
export const ROUTE_MESSAGES = '/meldingen';
export const ROUTE_THIRD_PARTY_COOKIES = `${ROUTE_MESSAGES}/cookies-derden-geblokkeerd`;
export const ROUTE_GEO_CHECK = `${ROUTE_MESSAGES}/geo-check`;
export const ROUTE_MESSAGES_ERROR_BLOCKED_OPEN_PAYMENT = `${ROUTE_MESSAGES}/openstaande-factuur`;
export const ROUTE_MESSAGES_ERROR_BLACK_FRIDAY = `${ROUTE_MESSAGES}/black-friday-is-voorbij`;
export const ROUTE_MESSAGES_ERROR_CANCELLATION_PERIOD = `${ROUTE_MESSAGES}/opzegtermijn`;
export const ROUTE_MESSAGES_ERROR_TERMED = `${ROUTE_MESSAGES}/account-via-voucher`;
export const ROUTE_MESSAGES_ERROR_TERMED_NPL = `${ROUTE_MESSAGES}/account-via-npl-voucher`;
export const ROUTE_MESSAGES_ERROR_SUBSCRIPTION_INCOMPATIBILITY = `${ROUTE_MESSAGES}/abonnement-incompatibiliteit`;
export const ROUTE_MESSAGES_ERROR_SUBSCRIPTION_PAUSED = `${ROUTE_MESSAGES}/kan-niet-pauzeren`;
export const ROUTE_MESSAGES_ERROR_MULTIPLE_SUBSCRIPTION = `${ROUTE_MESSAGES}/meerdere-abonnementen`;
export const ROUTE_MESSAGES_ERROR_ACTIVE_SUBSCRIPTION = `${ROUTE_MESSAGES}/actief-abonnement`;
export const ROUTE_MESSAGES_ERROR_ALREADY_ACTIVATED = `${ROUTE_MESSAGES}/al-geactiveerd-via/:partnerName`;
export const ROUTE_MESSAGES_ERROR_ALREADY_TRUSTED_PARTY = `${ROUTE_MESSAGES}/al-abonnement-via-derde/:partnerName?`;
export const ROUTE_MESSAGES_ERROR_INVALID_ENTITLEMENT = `${ROUTE_MESSAGES}/geen-recht-op-abonnement-via/:partnerName`;
export const ROUTE_MESSAGES_ERROR_CANNOT_ACTIVATE = `${ROUTE_MESSAGES}/kan-niet-activeren/:partnerName`;
export const ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED_PARTNER = `${ROUTE_MESSAGES}/abonnement-opgezegd-via/:partnerName`;
export const ROUTE_MESSAGES_ERROR_SUBSCRIPTION_CANCELLED = `${ROUTE_MESSAGES}/abonnement-beeindigd`;
export const ROUTE_MESSAGES_NEW_PLATFORM = `${ROUTE_MESSAGES}/nieuw-platform`;
export const ROUTE_MESSAGES_RESUME_PENDING_PAYMENT = `${ROUTE_MESSAGES}/betaling-afronden`;
export const ROUTE_MESSAGES_ERROR_INACTIVE_USER = `${ROUTE_MESSAGES}/account-inactief`;
export const ROUTE_ERROR = '/fout';
export const ROUTE_CODE = '/code';
export const ROUTE_PAYMENT_ACTIVATION = '/activeer';
export const ROUTE_ACTIVATE_VOUCHER = `${ROUTE_PAYMENT_ACTIVATION}/voucher`;
export const ROUTE_ACTIVATE_PLAN_STATUS = `${ROUTE_PAYMENT_ACTIVATION}/abonnement/:plan/status/:transactionMethod`;
export const ROUTE_ACTIVATE_PLAN = `${ROUTE_PAYMENT_ACTIVATION}/abonnement/:plan/:transactionMethod?/:transactionError?`;
export const ROUTE_PAYMENT_ACTIVATED = '/geactiveerd';
export const ROUTE_ACTIVATED_PLAN = `${ROUTE_PAYMENT_ACTIVATED}/abonnement/:plan`;
export const ROUTE_PPE = '/factuur-betalen';
export const ROUTE_PPE_PAID = '/factuur-betaald';
export const ROUTE_PAY_BAD_DEBT = '/alle-facturen-betalen';
export const ROUTE_PARTNER_SMS_CHECK = '/partner/tmobile/smscheck';
export const ROUTE_PARTNER = '/partner/:plan';
export const ROUTE_PARTNER_ACTIVATE = '/partner/:plan/activeer';
export const ROUTE_PARTNER_ACTIVATED = '/partner/:plan/geactiveerd';
export const ROUTE_NOT_FOUND = '/pagina-niet-gevonden';
export const ROUTE_TRIAL = '/proefperiode';
export const ROUTE_TRIAL_EXPIRED = `${ROUTE_TRIAL}/verlopen`;
export const ROUTE_NPL = '/nationale-postcode-loterij';
export const ROUTE_NPL_SHORT = '/postcodeloterij';
export const ROUTE_VRIENDENLOTERIJ = '/vriendenloterij';
export const ROUTE_EFTELING = '/efteling';
export const ROUTE_TMOBILE_THUIS = '/t-mobile-thuis';
export const ROUTE_DUTCH_FILM_FESTIVAL = '/nederlands-film-festival';
export const ROUTE_DUTCH_FILM_FESTIVAL_SHORT = '/NFF';
export const ROUTE_ALBERT_HEIJN = '/albert-heijn';
export const ROUTE_ALBERT_HEIJN_SHORT = '/ah';
export const ROUTE_ZIGGO = '/ziggo';
export const ROUTE_TMOBILE_STANDALONE_REDIRECT = '/t-mobile';
export const ROUTE_TMOBILE_STANDALONE_SHORT_REDIRECT = '/tmobile';
export const ROUTE_TMOBILE_STANDALONE = '/partner/tmobile-standalone';
export const ROUTE_YOUNG_CAPITAL = '/young-capital';
export const ROUTE_VRIENDEN_VAN_AMSTEL = '/vriendenvanamstellive';

export const NO_PROFILE_REQUIRED = [ROUTE_CONNECT];
