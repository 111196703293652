import { analyticsByRoute } from '../helpers/registration';
import {
	initiatePageViewTracking,
	getPlatformData,
	addToGlobalContexts,
	removeFromGlobalContext,
} from '../components/analytics/Snowplow';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router';
import {
	ROUTE_ACTIVATE_PLAN,
	ROUTE_ACTIVATE_PLAN_STATUS,
	ROUTE_ACTIVATED_PLAN,
	ROUTE_INVOICE,
	ROUTE_MESSAGES_ERROR_ALREADY_ACTIVATED,
	ROUTE_MESSAGES_ERROR_ALREADY_TRUSTED_PARTY,
	ROUTE_PARTNER,
} from '../constants/Routes';
import { useSelector } from 'react-redux';

export default function useInitTrackPageView(isLoading) {
	const location = useLocation();
	const gigyaUser = useSelector((state) => state.authentication.gigyaUser);

	const activateOrFinalizePath = matchPath(location.pathname, {
		path: [ROUTE_ACTIVATE_PLAN, ROUTE_ACTIVATED_PLAN],
		exact: false,
		strict: false,
	});

	const transformedPath = matchPath(window.location.pathname, {
		path: [
			ROUTE_MESSAGES_ERROR_ALREADY_TRUSTED_PARTY,
			ROUTE_MESSAGES_ERROR_ALREADY_ACTIVATED,
			ROUTE_INVOICE,
			ROUTE_ACTIVATE_PLAN_STATUS,
			ROUTE_PARTNER,
		],
		exact: false,
		strict: false,
	});
	const newLocation = transformedPath?.path ?? location.pathname;

	const data = analyticsByRoute[newLocation.replace(/\/+$/, '')];
	const locationToMap = activateOrFinalizePath?.path ?? location.pathname;

	useEffect(() => {
		const platformData = getPlatformData({ location: locationToMap });
		if (!platformData) return;

		addToGlobalContexts({
			rtlPlatform: { ...platformData },
		});
		return () => {
			removeFromGlobalContext({ rtlPlatform: { ...platformData } });
		};
	}, [locationToMap]);

	useEffect(() => {
		if (!isLoading) {
			const trackingData = data && {
				pageNamePlatform: data.pageName,
				rtlUser: data.useRtlUser && gigyaUser?.UID && { rtlUserId: gigyaUser.UID },
			};

			initiatePageViewTracking({
				...(trackingData && trackingData),
			});
		}
	}, [data, isLoading, gigyaUser.UID, activateOrFinalizePath]);
}
